import { Column } from "react-table";

import { Header } from "../../../../../../../hope-ui/components/table/Header";
import NamaGTKCell from "../../../../../../../hope-ui/components/table/NamaGTKCell";
import { useQueryRequest } from "../../../../../../../provider/query-request/absensi-b-inggris/kelompok-b-inggris/pindah-tutor";
import { useQueryResponsePagination } from "../../../../../../../provider/query-response/absensi-b-inggris/kelompok-b-inggris/pindah-tutor";
import moment from "moment";

export const Columns: ReadonlyArray<Column<any>> = [
  {
    Header: (props) => (
      <Header
        tableProps={props}
        title="No."
        className="ps-3 fs-6 text-center"
        queryRequest={useQueryRequest}
      />
    ),
    id: "no",
    Cell: ({ ...props }) => {
      const { offset } = useQueryResponsePagination();
      let page: any = offset;
      return (
        <label className="fw-normal text-dark pe-3 text-center w-100">
          {props.row.index + 1 + page}
        </label>
      );
    },
  },

  {
    Header: (props) => (
      <Header
        tableProps={props}
        title="Tanggal"
        className="ps-3 fs-6 text-center"
        queryRequest={useQueryRequest}
        isSorting={true}
      />
    ),
    id: "createdAt",
    Cell: ({ ...props }) => (
      <label className="fw-normal text-dark pe-3 text-center w-100">
        {moment(props.data[props.row.index].createdAt).format(
          "DD MMM YYYY HH:mm"
        )}
      </label>
    ),
  },

  {
    Header: (props) => (
      <Header
        tableProps={props}
        title="NIK"
        className="ps-3 fs-6 text-center"
        queryRequest={useQueryRequest}
        isSorting={true}
      />
    ),
    id: "nik_gtk",
    Cell: ({ ...props }) => (
      <label className="fw-normal text-dark pe-3 text-center w-100">
        {props.data[props.row.index].nik_gtk}
      </label>
    ),
  },

  {
    Header: (props) => (
      <Header
        tableProps={props}
        title="Nama GTK"
        className="ps-3 fs-6 text-center"
        queryRequest={useQueryRequest}
        isSorting={true}
      />
    ),
    id: "gtk.nama_gtk",
    Cell: ({ ...props }) => (
      <NamaGTKCell
        id={props.data[props.row.index].nik_gtk}
        label={props.data[props.row.index].gtk.nama_gtk}
      />
    ),
  },

  {
    Header: (props) => (
      <Header
        tableProps={props}
        title="NIK Tutor EMC Sebelumnya"
        className="ps-3 fs-6 text-center"
        queryRequest={useQueryRequest}
        isSorting={true}
      />
    ),
    id: "nik_tutor_lama",
    Cell: ({ ...props }) => (
      <label className="fw-normal text-dark pe-3 text-center w-100">
        {props.data[props.row.index].nik_tutor_lama}
      </label>
    ),
  },

  {
    Header: (props) => (
      <Header
        tableProps={props}
        title="Nama Tutor EMC Sebelumnya"
        className="ps-3 fs-6 text-center"
        queryRequest={useQueryRequest}
        isSorting={true}
      />
    ),
    id: "tutor_b_inggris_lama.nama_gtk",
    Cell: ({ ...props }) => (
      <label className="fw-normal text-dark pe-3 text-center w-100">
        {props.data[props.row.index].tutor_b_inggris_lama.nama_gtk}
      </label>
    ),
  },

  {
    Header: (props) => (
      <Header
        tableProps={props}
        title="Kelompok EMC Sebelumnya"
        className="ps-3 fs-6 text-center"
        queryRequest={useQueryRequest}
        isSorting={true}
      />
    ),
    id: "kelompok_b_inggris_lama.nama_kelompok_b_inggris",
    Cell: ({ ...props }) => (
      <label className="fw-normal text-dark pe-3 text-center w-100">
        {
          props.data[props.row.index].kelompok_b_inggris_lama
            .nama_kelompok_b_inggris
        }
      </label>
    ),
  },

  {
    Header: (props) => (
      <Header
        tableProps={props}
        title="NIK Tutor EMC Tujuan"
        className="ps-3 fs-6 text-center"
        queryRequest={useQueryRequest}
        isSorting={true}
      />
    ),
    id: "nik_tutor_tujuan",
    Cell: ({ ...props }) => (
      <label className="fw-normal text-dark pe-3 text-center w-100">
        {props.data[props.row.index].nik_tutor_tujuan}
      </label>
    ),
  },

  {
    Header: (props) => (
      <Header
        tableProps={props}
        title="Nama Tutor EMC Tujuan"
        className="ps-3 fs-6 text-center"
        queryRequest={useQueryRequest}
        isSorting={true}
      />
    ),
    id: "tutor_b_inggris_tujuan.nama_gtk",
    Cell: ({ ...props }) => (
      <label className="fw-normal text-dark pe-3 text-center w-100">
        {props.data[props.row.index].tutor_b_inggris_tujuan.nama_gtk}
      </label>
    ),
  },

  {
    Header: (props) => (
      <Header
        tableProps={props}
        title="Kelompok EMC Tujuan"
        className="ps-3 fs-6 text-center"
        queryRequest={useQueryRequest}
        isSorting={true}
      />
    ),
    id: "kelompok_b_inggris_tujuan.nama_kelompok_b_inggris",
    Cell: ({ ...props }) => (
      <label className="fw-normal text-dark pe-3 text-center w-100">
        {
          props.data[props.row.index].kelompok_b_inggris_tujuan
            .nama_kelompok_b_inggris
        }
      </label>
    ),
  },

  {
    Header: (props) => (
      <Header
        tableProps={props}
        title="Keterangan"
        className="ps-3 fs-6 text-center"
        queryRequest={useQueryRequest}
        isSorting={true}
      />
    ),
    id: "keterangan",
    Cell: ({ ...props }) => (
      <label className="fw-normal text-dark pe-3 text-center w-100">
        {props.data[props.row.index].keterangan}
      </label>
    ),
  },
];
