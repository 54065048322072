import { Fragment } from "react";
import {
  useQueryResponse,
  QueryResponseProvider,
} from "../../../provider/query-response/absensi-b-inggris";

import {
  useQueryRequest,
  QueryRequestProvider,
} from "../../../provider/query-request/absensi-b-inggris";

import {
  useQueryResponse as useQueryResponseDaftarAbsensi,
  QueryResponseProvider as QueryResponseProviderDaftarAbsensi,
} from "../../../provider/query-response/absensi-b-inggris/daftar-absensi";

import {
  useQueryRequest as useQueryRequestDaftarAbsensi,
  QueryRequestProvider as QueryRequestProviderDaftarAbsensi,
} from "../../../provider/query-request/absensi-b-inggris/daftar-absensi";

import Toolbar from "./toolbar";
import DaftarAbsensi from "./daftar-absensi";
import { Card } from "react-bootstrap";
import ModalKelompokBInggris from "../modal/kelompok-b-inggris";

import { QueryResponseProvider as QueryResponseProviderDataGTK } from "../../../provider/query-response/data-gtk";
import { QueryRequestProvider as QueryRequestProviderDataGTK } from "../../../provider/query-request/data-gtk";

import { QueryResponseProvider as QueryResponseProviderKelolaKelompok } from "../../../provider/query-response/absensi-b-inggris/kelompok-b-inggris/kelola-kelompok";
import { QueryRequestProvider as QueryRequestProviderKelolaKelompok } from "../../../provider/query-request/absensi-b-inggris/kelompok-b-inggris/kelola-kelompok";

import { QueryResponseProvider as QueryResponseProviderKelolaAnggotaKelompok } from "../../../provider/query-response/absensi-b-inggris/kelompok-b-inggris/kelola-anggota-kelompok";
import { QueryRequestProvider as QueryRequestProviderKelolaAnggotaKelompok } from "../../../provider/query-request/absensi-b-inggris/kelompok-b-inggris/kelola-anggota-kelompok";

import { QueryResponseProvider as QueryResponseProviderBuatAbsensi } from "../../../provider/query-response/absensi-b-inggris/buat-absensi";
import { QueryRequestProvider as QueryRequestProviderBuatAbsensi } from "../../../provider/query-request/absensi-b-inggris/buat-absensi";
import ModalBuatAbsensi from "../modal/buat-absensi";

import { QueryResponseProvider as QueryResponseProviderPindahTutor } from "../../../provider/query-response/absensi-b-inggris/kelompok-b-inggris/pindah-tutor";
import { QueryRequestProvider as QueryRequestProviderPindahTutor } from "../../../provider/query-request/absensi-b-inggris/kelompok-b-inggris/pindah-tutor";

const AbsensiBInggrisList = () => {
  const { activeTab, setActiveTab } = useQueryResponse();
  return (
    <Fragment>
      <ModalKelompokBInggris />
      <ModalBuatAbsensi />
      <Toolbar />
      <Card.Body className="pt-3">
        <button
          type="button"
          className={` ${
            activeTab === 1
              ? "bg-primary text-white border border-primary"
              : "bg-white text-dark border border-white"
          } cursor-pointer btn-md me-2 py-2 px-4 rounded-pill border border-white`}
          onClick={() => {
            setActiveTab(1);
          }}
        >
          Daftar Presensi EMC
        </button>

        {activeTab === 1 && <DaftarAbsensi />}
      </Card.Body>
    </Fragment>
  );
};

export const AbsensiBInggrisListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <QueryRequestProviderDaftarAbsensi>
        <QueryResponseProviderDaftarAbsensi>
          <QueryRequestProviderDataGTK>
            <QueryResponseProviderDataGTK>
              <QueryRequestProviderKelolaKelompok>
                <QueryResponseProviderKelolaKelompok>
                  <QueryRequestProviderKelolaAnggotaKelompok>
                    <QueryResponseProviderKelolaAnggotaKelompok>
                      <QueryRequestProviderPindahTutor>
                        <QueryResponseProviderPindahTutor>
                          <QueryRequestProviderBuatAbsensi>
                            <QueryResponseProviderBuatAbsensi>
                              <AbsensiBInggrisList />
                            </QueryResponseProviderBuatAbsensi>
                          </QueryRequestProviderBuatAbsensi>
                        </QueryResponseProviderPindahTutor>
                      </QueryRequestProviderPindahTutor>
                    </QueryResponseProviderKelolaAnggotaKelompok>
                  </QueryRequestProviderKelolaAnggotaKelompok>
                </QueryResponseProviderKelolaKelompok>
              </QueryRequestProviderKelolaKelompok>
            </QueryResponseProviderDataGTK>
          </QueryRequestProviderDataGTK>
        </QueryResponseProviderDaftarAbsensi>
      </QueryRequestProviderDaftarAbsensi>
    </QueryResponseProvider>
  </QueryRequestProvider>
);
