import axios, { AxiosResponse } from "axios";
import { client } from "../apiClient";
import { ApiConfig } from "../apiConfig";

const getAbsensiBInggris = async (query: string): Promise<any> => {
  return client
    .get(`${ApiConfig.GET_ABSENSI_B_INGGRIS_URL}?${query}`)
    .then((response: AxiosResponse<any>) => response.data);
};

const getLaporanAbsensiBInggris = async (query: string): Promise<any> => {
  return client
    .get(`${ApiConfig.GET_LAPORAN_ABSENSI_B_INGGRIS_URL}?${query}`)
    .then((response: AxiosResponse<any>) => response.data);
};

const getAllKelompokBInggris = async (nik_gtk: string): Promise<any> => {
  return client
    .get(`${ApiConfig.GET_ALL_KELOMPOK_B_INGGRIS_URL}/${nik_gtk}`)
    .then((response: AxiosResponse<any>) => response.data);
};

const getKelompokBInggris = async (query: string): Promise<any> => {
  return client
    .get(`${ApiConfig.GET_KELOMPOK_B_INGGRIS_URL}?${query}`)
    .then((response: AxiosResponse<any>) => response.data);
};

const getDetailKelompokBInggris = async (id: string): Promise<any> => {
  return client
    .get(`${ApiConfig.GET_DETAIL_KELOMPOK_B_INGGRIS_URL}/${id}`)
    .then((response: AxiosResponse<any>) => response.data);
};

const deleteKelompokBInggris = async (id: string): Promise<any> => {
  return client
    .delete(`${ApiConfig.DELETE_KELOMPOK_B_INGGRIS_URL}/${id}`)
    .then((response: AxiosResponse<any>) => response.data);
};

const postKelompokBInggris = async (data: FormData): Promise<any> => {
  return client
    .post(`${ApiConfig.POST_KELOMPOK_B_INGGRIS_URL}`, data)
    .then((response: AxiosResponse<any>) => response.data);
};

const getAnggotaKelompokBInggris = async (query: string): Promise<any> => {
  return client
    .get(`${ApiConfig.GET_ANGGOTA_KELOMPOK_B_INGGRIS_URL}?${query}`)
    .then((response: AxiosResponse<any>) => response.data);
};

const getListAnggotaKelompokBInggris = async (
  id_kelompok_lt3q: string
): Promise<any> => {
  return client
    .get(`${ApiConfig.GET_ANGGOTA_KELOMPOK_B_INGGRIS_URL}/${id_kelompok_lt3q}`)
    .then((response: AxiosResponse<any>) => response.data);
};

const getDetailAnggotaKelompokBInggris = async (id: string): Promise<any> => {
  return client
    .get(`${ApiConfig.GET_DETAIL_ANGGOTA_KELOMPOK_B_INGGRIS_URL}/${id}`)
    .then((response: AxiosResponse<any>) => response.data);
};

const deleteAnggotaKelompokBInggris = async (id: string): Promise<any> => {
  return client
    .delete(`${ApiConfig.DELETE_ANGGOTA_KELOMPOK_B_INGGRIS_URL}/${id}`)
    .then((response: AxiosResponse<any>) => response.data);
};

const postAnggotaKelompokBInggris = async (data: FormData): Promise<any> => {
  return client
    .post(`${ApiConfig.POST_ANGGOTA_KELOMPOK_B_INGGRIS_URL}`, data)
    .then((response: AxiosResponse<any>) => response.data);
};

const getAllMC = async (query: string): Promise<any> => {
  return client
    .get(`${ApiConfig.GET_ALL_MC_URL}?${query}`)
    .then((response: AxiosResponse<any>) => response.data);
};

const getKehadiranBInggris = async (query: string): Promise<any> => {
  return client
    .get(`${ApiConfig.GET_KEHADIRAN_B_INGGRIS_URL}?${query}`)
    .then((response: AxiosResponse<any>) => response.data);
};

const postBuatAbsensiBInggris = async (data: FormData): Promise<any> => {
  return client
    .post(`${ApiConfig.POST_BUAT_ABSENSI_B_INGGRIS_URL}`, data)
    .then((response: AxiosResponse<any>) => response.data);
};

const getDetailAbsensiBInggris = async (id: string): Promise<any> => {
  return client
    .get(`${ApiConfig.GET_DETAIL_ABSENSI_B_INGGRIS_URL}/${id}`)
    .then((response: AxiosResponse<any>) => response.data);
};

const getTotalInfaq = async (id: string): Promise<any> => {
  return client
    .get(`${ApiConfig.GET_TOTAL_INFAQ_URL}/${id}`)
    .then((response: AxiosResponse<any>) => response.data);
};

const deleteAbsensiBInggris = async (id: string): Promise<any> => {
  return client
    .delete(`${ApiConfig.DELETE_ABSENSI_B_INGGRIS_URL}/${id}`)
    .then((response: AxiosResponse<any>) => response.data);
};

const getPindahTutorBInggris = async (query: string): Promise<any> => {
  return client
    .get(`${ApiConfig.GET_PINDAH_TUTOR_B_INGGRIS_URL}?${query}`)
    .then((response: AxiosResponse<any>) => response.data);
};

const postPindahTutorBInggris = async (data: FormData): Promise<any> => {
  return client
    .post(`${ApiConfig.POST_PINDAH_TUTOR_B_INGGRIS_URL}`, data)
    .then((response: AxiosResponse<any>) => response.data);
};

const getTutorBInggris = async (nik_gtk: string): Promise<any> => {
  return client
    .get(`${ApiConfig.GET_TUTOR_B_INGGRIS_URL}/${nik_gtk}`)
    .then((response: AxiosResponse<any>) => response.data);
};

const getAllNilaiTahsin = async (group?: string): Promise<any> => {
  return client
    .get(`${ApiConfig.GET_ALL_NILAI_TAHSIN_URL}?group_by=${group}`)
    .then((response: AxiosResponse<any>) => response.data);
};

const postNilaiTahsin = async (data: FormData): Promise<any> => {
  return client
    .post(`${ApiConfig.POST_NILAI_TAHSIN_URL}`, data)
    .then((response: AxiosResponse<any>) => response.data);
};

export {
  getAbsensiBInggris,
  getLaporanAbsensiBInggris,
  getKelompokBInggris,
  postKelompokBInggris,
  getDetailKelompokBInggris,
  getAnggotaKelompokBInggris,
  postAnggotaKelompokBInggris,
  getDetailAnggotaKelompokBInggris,
  getAllKelompokBInggris,
  deleteKelompokBInggris,
  deleteAnggotaKelompokBInggris,
  getAllMC,
  getKehadiranBInggris,
  postBuatAbsensiBInggris,
  getDetailAbsensiBInggris,
  getTotalInfaq,
  deleteAbsensiBInggris,
  getListAnggotaKelompokBInggris,
  getPindahTutorBInggris,
  postPindahTutorBInggris,
  getTutorBInggris,
  getAllNilaiTahsin,
  postNilaiTahsin,
};
