import { App } from "../App";
import { FC } from "react";
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import { MasterLayout } from "../layout/MasterLayout";
import StatusAktivasiAktif from "../pages/status-aktivasi/aktif";
import StatusAktivasiTidakAktif from "../pages/status-aktivasi/tidak-aktif";
import Login from "../pages/login";
import DataGTK from "../pages/data-gtk";
import Dashboard from "../pages/dashboard";
import User from "../pages/user";
import FormEntryKepegawaian from "../pages/kepegawaian/entry/form";
import FormEntryGTK from "../pages/data-gtk/entry/form";
import AbsensiBPI from "../pages/absensi-bpi";
import Menu from "../pages/menu";
import HakAkses from "../pages/hak-akses";
import PresensiLT3Q from "../pages/presensi-lt3q";
import AbsensiGTK from "../pages/absensi-gtk";
import DataSaya from "../pages/data-saya";
import PageNotFound from "../pages/404-page-not-found";
import PelajaranYangDiampu from "../pages/pelajaran-yang-diampu";
import MasterPersensiGTK from "../pages/master-persensi-gtk";
import BackupData from "../pages/backup-data";
import PresensiLP3Q from "../pages/presensi-lt3q";
import ReportSaya from "../pages/report-saya";
import PresensiBPISaya from "../pages/presensi-saya/presensi-bpi";
import PresensiGTKSaya from "../pages/presensi-saya/presensi-gtk";
import PresensiLT3QSaya from "../pages/presensi-saya/presensi-lt3q";
import Kepegawaian from "../pages/kepegawaian";
import PrihalAlasanPresensiGTK from "../pages/presensi-saya/prihal-alasan-presensi-gtk";
import DaftarUnitKerja from "../pages/daftar-unit-kerja";
import FilterUnitKerja from "../pages/filter-unit-kerja";
import PresensiBahasaInggris from "../pages/presensi-b-inggris";
import SpeakingFocus from "../pages/speaking-focus";
const { PUBLIC_URL } = process.env;

const AppRoutes: FC = () => {
  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          <Route path="/login" element={<Login />} />
          <Route element={<MasterLayout />}>
            <Route path="/" element={<Dashboard />}></Route>

            <Route path="/dashboard" element={<Dashboard />}></Route>
            <Route path="/data-saya" element={<DataSaya />}></Route>
            <Route path="/data-saya/:nik_gtk" element={<DataSaya />}></Route>
            <Route
              path="/presensi-saya/presensi-bpi"
              element={<PresensiBPISaya />}
            ></Route>
            <Route
              path="/presensi-saya/presensi-gtk"
              element={<PresensiGTKSaya />}
            ></Route>
            <Route
              path="/presensi-saya/presensi-lt3q"
              element={<PresensiLT3QSaya />}
            ></Route>
            <Route path="/report-saya" element={<ReportSaya />}></Route>
            <Route
              path="/status-aktivasi/aktif"
              element={<StatusAktivasiAktif />}
            ></Route>

            <Route
              path="/status-aktivasi/tidak-aktif"
              element={<StatusAktivasiTidakAktif />}
            ></Route>

            <Route
              path="/olah-presensi/presensi-gtk"
              element={<AbsensiGTK />}
            ></Route>

            <Route
              path="/pengaturan/master-persensi-gtk"
              element={<MasterPersensiGTK />}
            ></Route>

            <Route path="/data-gtk/:status" element={<DataGTK />}></Route>
            <Route path="/data-gtk/form" element={<FormEntryGTK />}></Route>
            <Route
              path="/data-gtk/form/:nik_gtk"
              element={<FormEntryGTK />}
            ></Route>

            <Route path="/master-data/user" element={<User />}></Route>
            <Route
              path="/data-gtk/kepegawaian"
              element={<Kepegawaian />}
            ></Route>
            <Route
              path="/data-gtk/kepegawaian/form/:nik_gtk"
              element={<FormEntryKepegawaian />}
            ></Route>
            <Route
              path="/data-gtk/kepegawaian/form/:nik_gtk/:id"
              element={<FormEntryKepegawaian />}
            ></Route>
            <Route
              path="/olah-presensi/presensi-bpi"
              element={<AbsensiBPI />}
            ></Route>
            <Route
              path="/olah-presensi/presensi-lt3q"
              element={<PresensiLT3Q />}
            ></Route>
            <Route
              path="/olah-presensi/presensi-b-inggris"
              element={<PresensiBahasaInggris />}
            ></Route>
            {/* <Route path="/pengaturan/menu" element={<Menu />}></Route> */}
            <Route
              path="/pengaturan/pelajaran-yang-diampu"
              element={<PelajaranYangDiampu />}
            ></Route>
            <Route path="/pengaturan/hak-akses" element={<HakAkses />}></Route>
            <Route
              path="/pengaturan/backup-data"
              element={<BackupData />}
            ></Route>
            <Route
              path="/pengaturan/denda-presensi-gtk"
              element={<MasterPersensiGTK />}
            ></Route>
            <Route
              path="/pengaturan/prihal-alasan-presensi-gtk"
              element={<PrihalAlasanPresensiGTK />}
            ></Route>
            <Route
              path="/pengaturan/daftar-unit-kerja"
              element={<DaftarUnitKerja />}
            ></Route>
            <Route
              path="/pengaturan/filter-unit-kerja"
              element={<FilterUnitKerja />}
            ></Route>
            <Route
              path="/pengaturan/speaking-focus"
              element={<SpeakingFocus />}
            ></Route>
          </Route>

          <Route path="*" element={<PageNotFound />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
export { AppRoutes };
